<template svg>
  <svg
    width="362"
    height="46"
    viewBox="0 0 362 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_137_135)">
      <path
        d="M217.55 26.6132C217.498 26.6912 217.447 26.7951 217.37 26.8731C216.06 28.64 213.364 29.3676 210 28.8999C209.846 28.8739 209.692 28.8479 209.537 28.8219C207.509 28.4841 205.3 27.7306 203.092 26.6132C201.782 25.9377 200.472 25.1062 199.214 24.1447C197.853 23.1054 196.621 21.988 195.568 20.7928C193.898 18.9479 192.666 16.9991 191.87 15.1282C190.56 12.0361 190.56 9.28175 191.844 7.48883C193.154 5.7219 195.722 5.04631 198.932 5.54001C200.524 5.77387 202.27 6.31954 204.068 7.12505C205.84 7.93057 207.663 8.99592 209.435 10.3471C212.979 13.0235 215.649 16.2715 217.087 19.3377C218.474 22.2479 218.654 24.8463 217.55 26.6132Z"
        fill="#3EAE2B"
      />
      <path
        d="M217.55 26.6132C217.498 26.6912 217.447 26.7951 217.37 26.8731C216.06 28.64 213.364 29.3676 210 28.8998C209.846 28.8739 209.692 28.8479 209.537 28.8219C207.663 28.4321 205.506 27.7306 203.092 26.6132C201.782 25.9376 200.473 25.1061 199.214 24.1447C197.853 23.1054 196.621 21.988 195.568 20.7928C193 16.5054 193.539 11.1786 195.08 9.48961C196.954 7.41087 201.243 6.94315 207.945 11.4904C213.852 15.492 218.089 23.2613 217.55 26.6132Z"
        fill="black"
        fill-opacity="0.15"
      />
      <path
        d="M214.442 22.2479C214.442 22.7416 214.34 23.1833 214.16 23.5731C214.108 23.703 214.031 23.8329 213.929 23.9628C213.518 24.5345 212.85 24.8983 211.951 25.0801C211.053 25.262 209.974 25.2101 208.767 24.9762C206.353 24.5085 203.58 23.2353 201.14 21.4164C201.115 21.3904 201.063 21.3644 201.037 21.3384C198.675 19.5195 196.877 17.3109 195.876 15.2581C194.849 13.1534 194.772 11.3085 195.619 10.1652C196.441 9.02188 198.213 8.68409 200.498 9.1518C202.784 9.61952 205.429 10.8927 207.843 12.7376C210.257 14.5825 212.26 16.8172 213.415 18.9219C214.108 20.1431 214.442 21.2864 214.442 22.2479Z"
        fill="#3EAE2B"
      />
      <path
        d="M214.16 23.5731C214.108 23.7031 214.031 23.833 213.929 23.9629C213.518 24.5346 212.85 24.8983 211.951 25.0802C211.053 25.2621 209.974 25.2102 208.767 24.9763C206.353 24.5086 203.58 23.2353 201.14 21.4165C201.114 21.3905 201.063 21.3645 201.037 21.3385C198.187 18.4542 198.059 13.855 199.214 12.5558C200.524 11.3865 202.835 11.4125 207.072 14.2708C210.616 16.6873 213.877 21.1826 214.16 23.5731Z"
        fill="black"
        fill-opacity="0.15"
      />
      <path
        d="M210.616 21.4944C210.436 21.7282 210.128 21.8841 209.666 21.9621C209.204 22.0141 208.613 21.9881 207.92 21.8062C206.559 21.4684 204.915 20.6889 203.374 19.5196C201.834 18.3503 200.652 17.0251 199.985 15.8038C199.651 15.2062 199.471 14.6605 199.394 14.1928C199.343 13.7251 199.42 13.4133 199.599 13.1794C199.779 12.9456 200.062 12.7637 200.524 12.7117C200.96 12.6597 201.525 12.6857 202.193 12.8416C202.835 12.9975 203.58 13.2834 204.324 13.6731C205.069 14.0629 205.865 14.5566 206.61 15.1542C208.151 16.3235 209.383 17.6487 210.102 18.844C210.462 19.4416 210.667 19.9873 210.744 20.455C210.847 20.9487 210.77 21.2865 210.616 21.4944Z"
        fill="#3EAE2B"
      />
      <path
        d="M211.309 21.8061C211.078 22.4038 210.847 22.8455 210.462 23.3912C208.151 26.5093 203.323 26.951 199.933 24.3786C196.543 21.8061 195.67 16.999 197.956 13.8809C198.418 13.3093 198.752 12.9195 199.291 12.4778C199.188 12.5557 199.111 12.6337 199.034 12.7376C198.701 13.1794 198.624 13.725 198.701 14.2967C198.778 14.8683 199.009 15.5179 199.368 16.1675C200.087 17.4668 201.346 18.8959 202.963 20.1172C204.581 21.3384 206.302 22.1699 207.791 22.5077C208.536 22.6896 209.229 22.7416 209.794 22.6636C210.385 22.5857 210.898 22.3518 211.232 21.9101C211.258 21.9101 211.284 21.8581 211.309 21.8061Z"
        fill="#0068C7"
      />
      <path
        d="M214.879 24.0148C214.571 24.9502 213.441 26.6652 212.747 27.4447C209.101 32.3558 201.448 33.2132 195.953 29.0558C190.483 24.9762 189.482 16.6873 193.128 11.7502C193.693 10.9967 194.643 9.80143 195.465 9.09985C195.285 9.25576 195.105 9.43765 194.951 9.64552C193.821 11.1786 194.078 13.4132 195.131 15.6219C196.21 17.8565 198.136 20.1951 200.627 22.092C201.885 23.0534 203.22 23.8589 204.581 24.4825C205.942 25.1062 207.303 25.5739 208.562 25.8077C209.846 26.0676 211.027 26.1195 212.054 25.9117C213.081 25.7038 213.954 25.2621 214.545 24.4825C214.699 24.3266 214.802 24.1707 214.879 24.0148Z"
        fill="#0068C7"
      />
      <path
        d="M218.577 26.4054C218.012 27.9385 216.959 29.8353 215.495 31.5503C209.589 38.3582 199.368 39.6314 192.024 34.0448C184.705 28.5101 182.985 18.3503 187.787 10.633C188.865 8.89203 190.945 6.83928 192.178 5.95581C192.152 5.98179 191.459 6.6314 191.176 7.02116C189.61 9.15187 189.738 12.296 191.099 15.466C192.46 18.6881 195.08 22.066 198.701 24.8204C200.524 26.1975 202.424 27.2889 204.324 28.1204C206.225 28.9519 208.125 29.4716 209.871 29.7314C213.364 30.1991 216.445 29.5235 218.012 27.3928C218.243 27.081 218.448 26.7432 218.577 26.4054Z"
        fill="#0068C7"
      />
      <path
        d="M217.832 18.9739C216.317 15.7779 213.544 12.4259 209.923 9.67158C206.328 6.91725 202.424 5.22828 199.06 4.70859C197.853 4.5267 196.672 4.50072 195.619 4.63064C195.568 4.63064 195.516 4.63064 195.491 4.65662C195.337 4.68261 195.157 4.70859 195.003 4.73458C194.977 4.73458 194.951 4.73458 194.9 4.76056C194.797 4.78654 194.695 4.81253 194.618 4.83851C194.515 4.8645 194.438 4.89048 194.361 4.91647C194.284 4.94245 194.207 4.96843 194.13 4.99442C193.385 5.22828 192.717 5.59206 192.127 6.05977C191.767 6.31962 191.459 6.63143 191.176 7.02119C189.61 9.1519 189.738 12.296 191.099 15.4661C192.46 18.6881 195.08 22.0661 198.701 24.8204C200.524 26.1976 202.424 27.2889 204.324 28.1204C206.225 28.9519 208.125 29.4716 209.871 29.7314C213.364 30.1991 216.445 29.5236 218.012 27.3928C218.243 27.107 218.423 26.7692 218.577 26.4314C219.527 24.3527 219.116 21.6763 217.832 18.9739ZM217.55 26.6133C217.498 26.6913 217.447 26.7952 217.37 26.8732C216.06 28.6401 213.364 29.3676 210 28.8999C209.846 28.8739 209.692 28.848 209.537 28.822C207.509 28.4842 205.3 27.7306 203.092 26.6133C201.782 25.9377 200.473 25.1062 199.214 24.1448C197.853 23.1054 196.621 21.9881 195.568 20.7928C193.899 18.948 192.666 16.9991 191.87 15.1283C190.56 12.0362 190.56 9.28182 191.844 7.48891C193.154 5.72198 195.722 5.04639 198.932 5.54009C200.524 5.77395 202.27 6.31962 204.068 7.12513C205.84 7.93064 207.663 8.99599 209.435 10.3472C212.979 13.0236 215.649 16.2716 217.087 19.3377C218.474 22.248 218.654 24.8464 217.55 26.6133Z"
        fill="white"
      />
      <path
        d="M214.16 18.5061C212.953 16.2975 210.873 13.9589 208.382 12.062C205.891 10.1652 203.143 8.84 200.704 8.3463C198.88 7.95654 197.16 8.03449 195.927 8.78803C195.901 8.78803 195.876 8.81402 195.876 8.81402C195.876 8.81402 195.85 8.81402 195.85 8.84C195.799 8.86599 195.747 8.89197 195.722 8.94394C195.645 8.99591 195.568 9.07386 195.491 9.12583C195.311 9.28173 195.131 9.46362 194.977 9.6715C193.847 11.2046 194.104 13.4392 195.157 15.6479C196.235 17.8825 198.161 20.2211 200.652 22.118C201.911 23.0794 203.246 23.8849 204.607 24.5085C205.968 25.1321 207.329 25.5998 208.587 25.8337C209.871 26.0935 211.053 26.1455 212.08 25.9376C213.107 25.7298 213.98 25.288 214.571 24.5085C214.673 24.3786 214.776 24.2227 214.853 24.0668C215.675 22.5077 215.238 20.4809 214.16 18.5061ZM213.929 23.9628C213.518 24.5345 212.85 24.8983 211.951 25.0802C211.053 25.262 209.974 25.2101 208.767 24.9762C206.353 24.5085 203.58 23.2353 201.14 21.4164C198.726 19.5975 196.877 17.3369 195.876 15.2581C194.849 13.1534 194.772 11.3085 195.619 10.1652C196.441 9.02189 198.213 8.6841 200.498 9.15181C202.784 9.61953 205.429 10.8928 207.843 12.7376C210.256 14.5825 212.259 16.8172 213.415 18.9219C214.108 20.1691 214.442 21.3124 214.442 22.2739C214.442 22.9235 214.288 23.4951 213.929 23.9628Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M211.515 20.351C211.412 19.7793 211.155 19.1297 210.77 18.4801C210 17.1809 208.716 15.7778 207.098 14.5565C205.506 13.3352 203.837 12.4778 202.398 12.14C201.679 11.9581 201.012 11.9061 200.447 11.9841C200.01 12.036 199.599 12.2179 199.291 12.4778C199.189 12.5557 199.111 12.6337 199.034 12.7376C198.701 13.1793 198.624 13.725 198.701 14.2967C198.778 14.8683 199.009 15.5179 199.368 16.1675C200.087 17.4667 201.346 18.8959 202.963 20.1171C204.581 21.3384 206.302 22.1699 207.791 22.5077C208.536 22.6896 209.229 22.7415 209.794 22.6636C210.385 22.5856 210.899 22.3518 211.232 21.91C211.258 21.8581 211.284 21.8321 211.309 21.7801C211.54 21.3904 211.592 20.8707 211.515 20.351ZM210.616 21.4943C210.436 21.7281 210.128 21.8841 209.666 21.962C209.204 22.014 208.613 21.988 207.92 21.8061C206.559 21.4683 204.915 20.6888 203.374 19.5195C201.834 18.3502 200.652 17.025 199.985 15.8037C199.651 15.2061 199.471 14.6604 199.394 14.1927C199.343 13.725 199.42 13.4132 199.599 13.1793C199.779 12.9455 200.062 12.7636 200.524 12.7116C200.96 12.6596 201.525 12.6856 202.193 12.8415C202.835 12.9974 203.58 13.2833 204.324 13.673C205.069 14.0628 205.865 14.5565 206.61 15.1541C208.151 16.3234 209.383 17.6486 210.102 18.8439C210.462 19.4415 210.667 19.9872 210.744 20.4549C210.847 20.9486 210.77 21.2864 210.616 21.4943Z"
        fill="white"
      />
    </g>
    <path
      d="M246.292 9.76333H243.172L235.252 31.1633L227.372 9.76333H224.252L233.692 35.0433H236.852L246.292 9.76333ZM261.251 25.4033C261.251 29.8033 260.131 32.6033 255.691 32.6033C251.251 32.6033 250.091 29.8033 250.091 25.4033C250.091 21.0033 251.251 18.2033 255.691 18.2033C260.131 18.2033 261.251 21.0033 261.251 25.4033ZM255.691 35.3633C261.771 35.3633 264.171 31.2833 264.171 25.4033C264.171 19.4033 261.891 15.4433 255.691 15.4433C249.571 15.4433 247.171 19.5233 247.171 25.4033C247.171 31.4033 249.451 35.3633 255.691 35.3633ZM268.747 29.6833C268.747 33.8433 270.827 35.2833 273.587 35.2833C274.627 35.2833 275.427 35.1233 275.947 34.8833L275.547 32.2433C275.107 32.4433 274.507 32.5233 273.827 32.5233C272.467 32.5233 271.667 31.8833 271.667 29.2433V7.76333H268.747V29.6833Z"
      fill="#3EAE2B"
    />
    <path
      d="M299.261 9.76333H296.141L288.221 31.1633L280.341 9.76333H277.221L286.661 35.0433H289.821L299.261 9.76333ZM302.505 35.0433H305.425V15.7633H302.505V35.0433ZM302.065 9.16333C302.065 10.2033 302.905 11.0433 303.985 11.0433C305.025 11.0433 305.865 10.2033 305.865 9.16333C305.865 8.12333 305.025 7.24333 303.985 7.24333C302.905 7.24333 302.065 8.08333 302.065 9.16333ZM326.615 23.8833C326.615 18.2833 323.615 15.4433 318.535 15.4433C312.215 15.4433 310.175 19.8833 310.175 25.4433C310.175 32.2833 313.295 35.3633 319.455 35.3633C321.815 35.3633 324.095 34.8833 325.975 34.0433L325.535 31.2833C324.095 32.0433 321.735 32.6033 319.495 32.6033C315.495 32.6033 313.135 30.8033 313.135 26.7233V26.5633H326.295C326.535 25.7233 326.615 24.6833 326.615 23.8833ZM313.135 23.8433C313.135 19.6833 315.215 18.0433 318.455 18.0433C321.735 18.0433 323.815 19.7233 323.815 23.8433V24.0833H313.135V23.8433ZM332.648 15.7633H329.648L336.368 35.0433H339.368L344.608 20.2833L349.848 35.0433H352.848L359.568 15.7633H356.568L351.328 31.0833L346.088 15.7633H343.128L337.888 31.0833L332.648 15.7633Z"
      fill="#0068C7"
    />
    <path
      d="M8.87229 5.59206L0.781128 14.7137L2.74742 16.9885L13.3699 5.00647H10.1831C9.68593 5.00647 9.2113 5.20917 8.87229 5.59206Z"
      fill="#3EAE2B"
    />
    <path
      d="M15.0876 5.59206L3.85486 18.2497L5.82115 20.5245L19.5852 5.00647H16.3984C15.9012 5.00647 15.404 5.20917 15.0876 5.59206Z"
      fill="#3EAE2B"
    />
    <path
      d="M25.7778 5.00647H22.6137C22.1165 5.00647 21.6193 5.23169 21.2802 5.59206L6.90601 21.8083L19.879 36.8308C20.218 37.2137 20.6926 37.4389 21.2124 37.4389H24.3314L10.8386 21.8308L25.7778 5.00647Z"
      fill="#3EAE2B"
    />
    <path
      d="M12.059 37.4389L0.781128 24.3983V28.9479L7.60663 36.8307C7.94565 37.2136 8.42027 37.4389 8.94009 37.4389H12.059Z"
      fill="#0068C7"
    />
    <path
      d="M18.2065 37.4389L0.781128 17.2812V21.8308L13.7541 36.8308C14.0931 37.2137 14.5677 37.4389 15.0876 37.4389H18.2065Z"
      fill="#0068C7"
    />
    <path
      d="M31.2925 7.37134H34.2532V35.029H31.2925V7.37134ZM43.2936 15.4794H47.068L38.9316 24.7137L47.8816 35.0065H44.1073L35.1573 24.7137L43.2936 15.4794Z"
      fill="#0068C7"
    />
    <path
      d="M51.6786 6.85327C52.7408 6.85327 53.5996 7.75417 53.5996 8.79021C53.5996 9.84877 52.7408 10.7046 51.6786 10.7046C50.5711 10.7046 49.7349 9.84877 49.7349 8.79021C49.7349 7.70913 50.5937 6.85327 51.6786 6.85327ZM50.1869 15.4794H53.1476V35.0289H50.1869V15.4794Z"
      fill="#0068C7"
    />
    <path
      d="M57.6453 10.2091H60.606V15.4794H68.4486V18.2722H60.606V28.1596C60.606 31.5154 62.1203 32.5289 64.3804 32.5289C66.0077 32.5289 67.7253 32.1235 68.8554 31.6055L69.3074 34.3983C67.9965 34.9614 66.2111 35.3217 64.3804 35.3217C60.4252 35.3217 57.6227 33.4974 57.6227 28.137V10.2091H57.6453Z"
      fill="#0068C7"
    />
    <path
      d="M79.0032 31.0199L84.337 15.4794H87.343L92.6768 30.9974L97.988 15.4794H101.039L94.2137 35.0289H91.1625L85.8287 20.074L80.4949 35.0289H77.4437L70.6182 15.4794H73.6694L79.0032 31.0199Z"
      fill="#0068C7"
    />
    <path
      d="M117.109 34.2182C115.278 34.8713 112.385 35.3443 109.673 35.3443C104.949 35.3443 101.83 33.7677 101.83 29.3533C101.83 24.9839 104.972 23.3173 110.283 23.3173H114.148V21.4029C114.148 18.7227 112.272 17.8668 109.176 17.8668C106.893 17.8668 105.017 18.2722 103.797 18.7227L103.345 15.9975C104.927 15.547 106.915 15.1416 109.289 15.1416C113.764 15.1416 117.109 16.7632 117.109 21.6281V34.2182ZM114.148 25.6596H110.328C106.712 25.6596 104.791 26.5605 104.791 29.3083C104.791 32.056 106.712 32.7993 109.582 32.7993C111.006 32.7993 112.882 32.6416 114.148 32.2362V25.6596Z"
      fill="#0068C7"
    />
    <path
      d="M121.651 16.2902C122.962 15.6821 125.403 15.1866 127.799 15.1866C129.336 15.1866 130.647 15.3443 131.709 15.6821L131.144 18.4299C130.534 18.1821 129.313 17.9794 127.889 17.9794C126.217 17.9794 125.335 18.1821 124.635 18.4975V35.0065H121.674V16.2902H121.651Z"
      fill="#0068C7"
    />
    <path
      d="M148.931 26.4253H135.528V26.583C135.528 30.7271 137.924 32.5515 141.992 32.5515C144.275 32.5515 146.671 31.9884 148.14 31.2226L148.592 34.0154C146.671 34.8713 144.365 35.3442 141.97 35.3442C135.709 35.3442 132.522 32.2136 132.522 25.2992C132.522 19.6686 134.602 15.1641 141.02 15.1641C146.196 15.1641 149.247 18.0469 149.247 23.7226C149.247 24.5334 149.157 25.5695 148.931 26.4253ZM135.528 23.9253H146.399V23.6776C146.399 19.5109 144.275 17.7992 140.953 17.7992C137.653 17.7992 135.551 19.4659 135.551 23.6776V23.9253H135.528Z"
      fill="#0068C7"
    />
    <line
      x1="170.559"
      y1="0.935669"
      x2="170.559"
      y2="42.4279"
      stroke="#CACACA"
    />
    <defs>
      <clipPath id="clip0_137_135">
        <rect
          width="34"
          height="33"
          fill="white"
          transform="translate(185.09 4.55261)"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'volview-full-logo',
};
</script>
