<template svg>
  <svg
    width="178"
    height="41"
    viewBox="0 0 178 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_137_151)">
      <path
        d="M33.2403 22.3722C33.189 22.4501 33.1376 22.554 33.0606 22.632C31.7509 24.3989 29.0545 25.1265 25.6905 24.6588C25.5364 24.6328 25.3823 24.6068 25.2282 24.5808C23.1995 24.243 20.9911 23.4895 18.7826 22.3722C17.4729 21.6966 16.1633 20.8651 14.905 19.9037C13.5439 18.8643 12.3113 17.747 11.2584 16.5517C9.58926 14.7068 8.35663 12.758 7.56056 10.8871C6.25089 7.79499 6.25089 5.04066 7.53488 3.24774C8.84455 1.48082 11.4125 0.805225 14.6225 1.29893C16.2146 1.53278 17.9609 2.07845 19.7584 2.88396C21.5303 3.68948 23.3536 4.75483 25.1255 6.10601C28.6693 8.78239 31.34 12.0304 32.7781 15.0966C34.1648 18.0068 34.3445 20.6052 33.2403 22.3722Z"
        fill="#3EAE2B"
      />
      <path
        d="M33.2404 22.3721C33.189 22.4501 33.1377 22.554 33.0606 22.632C31.7509 24.3989 29.0546 25.1265 25.6905 24.6588C25.5364 24.6328 25.3824 24.6068 25.2283 24.5808C23.3537 24.191 21.1966 23.4895 18.7827 22.3721C17.473 21.6966 16.1633 20.8651 14.905 19.9036C13.544 18.8643 12.3114 17.7469 11.2585 16.5517C8.69052 12.2643 9.2298 6.9375 10.7706 5.24852C12.6452 3.16978 16.9337 2.70206 23.6361 7.24931C29.5425 11.2509 33.7796 19.0202 33.2404 22.3721Z"
        fill="black"
        fill-opacity="0.15"
      />
      <path
        d="M30.1331 18.0068C30.1331 18.5005 30.0304 18.9422 29.8506 19.332C29.7993 19.4619 29.7222 19.5918 29.6195 19.7217C29.2086 20.2934 28.541 20.6572 27.6422 20.8391C26.7434 21.021 25.6648 20.969 24.4579 20.7351C22.044 20.2674 19.2706 18.9942 16.831 17.1753C16.8053 17.1493 16.7539 17.1233 16.7283 17.0973C14.3657 15.2784 12.5681 13.0698 11.5666 11.017C10.5394 8.91229 10.4624 7.06741 11.3098 5.9241C12.1316 4.78079 13.9035 4.443 16.189 4.91071C18.4745 5.37843 21.1195 6.65166 23.5334 8.49654C25.9473 10.3414 27.9503 12.5761 29.1059 14.6808C29.7993 15.9021 30.1331 17.0454 30.1331 18.0068Z"
        fill="#3EAE2B"
      />
      <path
        d="M29.8506 19.3321C29.7992 19.462 29.7222 19.5919 29.6195 19.7218C29.2086 20.2935 28.5409 20.6573 27.6421 20.8391C26.7433 21.021 25.6648 20.9691 24.4578 20.7352C22.0439 20.2675 19.2705 18.9943 16.831 17.1754C16.8053 17.1494 16.7539 17.1234 16.7282 17.0974C13.8778 14.2132 13.7494 9.61394 14.905 8.31473C16.2146 7.14544 18.5258 7.17142 22.763 10.0297C26.3068 12.4462 29.5681 16.9415 29.8506 19.3321Z"
        fill="black"
        fill-opacity="0.15"
      />
      <path
        d="M26.3068 17.2533C26.1271 17.4871 25.8189 17.6431 25.3567 17.721C24.8944 17.773 24.3038 17.747 23.6105 17.5651C22.2494 17.2273 20.6059 16.4478 19.0651 15.2785C17.5243 14.1092 16.3431 12.784 15.6754 11.5627C15.3416 10.9651 15.1618 10.4194 15.0848 9.95171C15.0334 9.484 15.1105 9.17219 15.2902 8.93833C15.47 8.70447 15.7524 8.52258 16.2147 8.47061C16.6512 8.41864 17.2162 8.44463 17.8839 8.60053C18.5259 8.75644 19.2706 9.04227 20.0153 9.43203C20.76 9.82179 21.5561 10.3155 22.3008 10.9131C23.8416 12.0824 25.0742 13.4076 25.7932 14.6029C26.1528 15.2005 26.3582 15.7462 26.4352 16.2139C26.5379 16.7076 26.4609 17.0454 26.3068 17.2533Z"
        fill="#3EAE2B"
      />
      <path
        d="M27.0001 17.565C26.769 18.1627 26.5379 18.6044 26.1527 19.1501C23.8415 22.2682 19.0137 22.7099 15.624 20.1375C12.2343 17.565 11.3612 12.758 13.6467 9.63984C14.1089 9.06819 14.4427 8.67843 14.982 8.23669C14.8793 8.31465 14.8022 8.3926 14.7252 8.49654C14.3914 8.93827 14.3143 9.48394 14.3914 10.0556C14.4684 10.6272 14.6995 11.2769 15.059 11.9265C15.7781 13.2257 17.0364 14.6548 18.6542 15.8761C20.272 17.0973 21.9926 17.9288 23.482 18.2666C24.2267 18.4485 24.9201 18.5005 25.485 18.4225C26.0757 18.3446 26.5893 18.1107 26.9231 17.669C26.9488 17.669 26.9745 17.617 27.0001 17.565Z"
        fill="#0068C7"
      />
      <path
        d="M30.5696 19.7737C30.2615 20.7092 29.1316 22.4241 28.4382 23.2036C24.7917 28.1147 17.1391 28.9722 11.6436 24.8147C6.17386 20.7351 5.17235 12.4462 8.81888 7.50916C9.38383 6.75562 10.334 5.56034 11.1557 4.85876C10.976 5.01467 10.7962 5.19656 10.6421 5.40443C9.51223 6.93751 9.76903 9.17215 10.8219 11.3808C11.9004 13.6155 13.8264 15.954 16.3174 17.8509C17.5757 18.8123 18.911 19.6178 20.272 20.2414C21.6331 20.8651 22.9941 21.3328 24.2524 21.5666C25.5364 21.8265 26.7177 21.8784 27.7449 21.6706C28.7721 21.4627 29.6452 21.021 30.2358 20.2414C30.3899 20.0855 30.4926 19.9296 30.5696 19.7737Z"
        fill="#0068C7"
      />
      <path
        d="M34.2675 22.1643C33.7025 23.6974 32.6497 25.5942 31.1859 27.3092C25.2796 34.1171 15.059 35.3903 7.71463 29.8037C0.395901 24.2691 -1.32464 14.1092 3.47747 6.39189C4.55602 4.65094 6.63608 2.59819 7.86871 1.71472C7.84303 1.74071 7.14968 2.39031 6.8672 2.78008C5.30074 4.91078 5.42913 8.05488 6.79016 11.225C8.15119 14.447 10.7705 17.825 14.3914 20.5793C16.2146 21.9565 18.1149 23.0478 20.0152 23.8793C21.9155 24.7108 23.8158 25.2305 25.5621 25.4903C29.0545 25.958 32.1361 25.2824 33.7026 23.1517C33.9337 22.8399 34.1391 22.5021 34.2675 22.1643Z"
        fill="#0068C7"
      />
      <path
        d="M33.5228 14.7329C32.0077 11.5368 29.2343 8.18483 25.6135 5.4305C22.0183 2.67616 18.115 0.987188 14.7509 0.467503C13.544 0.285613 12.3627 0.259629 11.3098 0.38955C11.2585 0.38955 11.2071 0.38955 11.1814 0.415535C11.0274 0.441519 10.8476 0.467503 10.6935 0.493487C10.6679 0.493487 10.6422 0.493487 10.5908 0.519472C10.4881 0.545456 10.3854 0.57144 10.3083 0.597424C10.2056 0.623409 10.1286 0.649393 10.0515 0.675377C9.9745 0.701361 9.89746 0.727346 9.82042 0.75333C9.07571 0.987188 8.40803 1.35097 7.8174 1.81868C7.45788 2.07853 7.14973 2.39034 6.86725 2.7801C5.30078 4.91081 5.42918 8.0549 6.79021 11.225C8.15124 14.447 10.7706 17.825 14.3914 20.5793C16.2147 21.9565 18.115 23.0478 20.0153 23.8793C21.9156 24.7108 23.8159 25.2305 25.5621 25.4903C29.0546 25.9581 32.1361 25.2825 33.7026 23.1518C33.9337 22.8659 34.1135 22.5281 34.2675 22.1903C35.2177 20.1116 34.8068 17.4352 33.5228 14.7329ZM33.2404 22.3722C33.189 22.4502 33.1376 22.5541 33.0606 22.6321C31.7509 24.399 29.0546 25.1266 25.6905 24.6588C25.5364 24.6329 25.3824 24.6069 25.2283 24.5809C23.1996 24.2431 20.9911 23.4895 18.7827 22.3722C17.473 21.6966 16.1633 20.8651 14.905 19.9037C13.544 18.8644 12.3114 17.747 11.2585 16.5518C9.5893 14.7069 8.35667 12.7581 7.5606 10.8872C6.25093 7.79506 6.25093 5.04073 7.53492 3.24782C8.84459 1.48089 11.4126 0.805298 14.6225 1.299C16.2147 1.53286 17.9609 2.07853 19.7585 2.88404C21.5304 3.68955 23.3537 4.7549 25.1256 6.10609C28.6694 8.78246 31.3401 12.0305 32.7781 15.0966C34.1648 18.0069 34.3446 20.6053 33.2404 22.3722Z"
        fill="white"
      />
      <path
        d="M29.8506 14.2651C28.6436 12.0564 26.5636 9.71781 24.0726 7.82096C21.5817 5.92411 18.834 4.59891 16.3944 4.10521C14.5711 3.71545 12.8506 3.7934 11.618 4.54694C11.5923 4.54694 11.5666 4.57293 11.5666 4.57293C11.5666 4.57293 11.5409 4.57293 11.5409 4.59891C11.4896 4.6249 11.4382 4.65088 11.4125 4.70285C11.3355 4.75482 11.2584 4.83277 11.1814 4.88474C11.0016 5.04065 10.8219 5.22254 10.6678 5.43041C9.5379 6.96348 9.79469 9.19813 10.8476 11.4068C11.9261 13.6414 13.8521 15.98 16.343 17.8769C17.6013 18.8383 18.9367 19.6438 20.2977 20.2674C21.6587 20.891 23.0198 21.3588 24.2781 21.5926C25.5621 21.8525 26.7433 21.9044 27.7705 21.6966C28.7977 21.4887 29.6708 21.0469 30.2615 20.2674C30.3642 20.1375 30.4669 19.9816 30.5439 19.8257C31.3657 18.2666 30.9291 16.2399 29.8506 14.2651ZM29.6195 19.7217C29.2086 20.2934 28.5409 20.6572 27.6421 20.8391C26.7433 21.021 25.6648 20.969 24.4578 20.7351C22.0439 20.2674 19.2705 18.9942 16.8309 17.1753C14.4171 15.3564 12.5681 13.0958 11.5666 11.017C10.5394 8.9123 10.4624 7.06742 11.3098 5.92411C12.1316 4.7808 13.9035 4.44301 16.189 4.91072C18.4745 5.37844 21.1195 6.65167 23.5334 8.49655C25.9473 10.3414 27.9503 12.5761 29.1059 14.6808C29.7992 15.928 30.1331 17.0714 30.1331 18.0328C30.1331 18.6824 29.979 19.254 29.6195 19.7217Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M27.2056 16.1099C27.1029 15.5382 26.8461 14.8886 26.4609 14.239C25.6905 12.9398 24.4065 11.5367 22.7887 10.3154C21.1965 9.09415 19.5274 8.23667 18.0893 7.89887C17.3703 7.71698 16.7026 7.66501 16.1376 7.74296C15.7011 7.79493 15.2902 7.97682 14.982 8.23667C14.8793 8.31462 14.8023 8.39257 14.7252 8.49651C14.3914 8.93824 14.3144 9.48391 14.3914 10.0556C14.4684 10.6272 14.6996 11.2768 15.0591 11.9264C15.7781 13.2256 17.0364 14.6548 18.6542 15.876C20.2721 17.0973 21.9926 17.9288 23.482 18.2666C24.2268 18.4485 24.9201 18.5004 25.4851 18.4225C26.0757 18.3445 26.5893 18.1107 26.9231 17.6689C26.9488 17.617 26.9745 17.591 27.0002 17.539C27.2313 17.1493 27.2826 16.6296 27.2056 16.1099ZM26.3068 17.2532C26.1271 17.4871 25.8189 17.643 25.3567 17.7209C24.8944 17.7729 24.3038 17.7469 23.6104 17.565C22.2494 17.2272 20.6059 16.4477 19.0651 15.2784C17.5243 14.1091 16.3431 12.7839 15.6754 11.5626C15.3416 10.965 15.1618 10.4193 15.0848 9.95163C15.0334 9.48391 15.1104 9.1721 15.2902 8.93824C15.47 8.70438 15.7524 8.52249 16.2147 8.47052C16.6512 8.41855 17.2162 8.44454 17.8839 8.60044C18.5259 8.75635 19.2706 9.04218 20.0153 9.43194C20.76 9.8217 21.5561 10.3154 22.3008 10.913C23.8416 12.0823 25.0742 13.4075 25.7932 14.6028C26.1527 15.2004 26.3582 15.7461 26.4352 16.2138C26.5379 16.7075 26.4609 17.0453 26.3068 17.2532Z"
        fill="white"
      />
    </g>
    <path
      d="M61.9829 5.52224H58.8629L50.9429 26.9222L43.0629 5.52224H39.9429L49.3829 30.8022H52.5429L61.9829 5.52224ZM76.9417 21.1622C76.9417 25.5622 75.8217 28.3622 71.3817 28.3622C66.9417 28.3622 65.7817 25.5622 65.7817 21.1622C65.7817 16.7622 66.9417 13.9622 71.3817 13.9622C75.8217 13.9622 76.9417 16.7622 76.9417 21.1622ZM71.3817 31.1222C77.4617 31.1222 79.8617 27.0422 79.8617 21.1622C79.8617 15.1622 77.5817 11.2022 71.3817 11.2022C65.2617 11.2022 62.8617 15.2822 62.8617 21.1622C62.8617 27.1622 65.1417 31.1222 71.3817 31.1222ZM84.4373 25.4422C84.4373 29.6022 86.5173 31.0422 89.2773 31.0422C90.3173 31.0422 91.1173 30.8822 91.6373 30.6422L91.2373 28.0022C90.7973 28.2022 90.1973 28.2822 89.5173 28.2822C88.1573 28.2822 87.3573 27.6422 87.3573 25.0022V3.52224H84.4373V25.4422Z"
      fill="#3EAE2B"
    />
    <path
      d="M114.952 5.52224H111.832L103.912 26.9222L96.0317 5.52224H92.9117L102.352 30.8022H105.512L114.952 5.52224ZM118.196 30.8022H121.116V11.5222H118.196V30.8022ZM117.756 4.92225C117.756 5.96224 118.596 6.80225 119.676 6.80225C120.716 6.80225 121.556 5.96224 121.556 4.92225C121.556 3.88224 120.716 3.00224 119.676 3.00224C118.596 3.00224 117.756 3.84225 117.756 4.92225ZM142.306 19.6422C142.306 14.0422 139.306 11.2022 134.226 11.2022C127.906 11.2022 125.866 15.6422 125.866 21.2022C125.866 28.0422 128.986 31.1222 135.146 31.1222C137.506 31.1222 139.786 30.6422 141.666 29.8022L141.226 27.0422C139.786 27.8022 137.426 28.3622 135.186 28.3622C131.186 28.3622 128.826 26.5622 128.826 22.4822V22.3222H141.986C142.226 21.4822 142.306 20.4422 142.306 19.6422ZM128.826 19.6022C128.826 15.4422 130.906 13.8022 134.146 13.8022C137.426 13.8022 139.506 15.4822 139.506 19.6022V19.8422H128.826V19.6022ZM148.339 11.5222H145.339L152.059 30.8022H155.059L160.299 16.0422L165.539 30.8022H168.539L175.259 11.5222H172.259L167.019 26.8422L161.779 11.5222H158.819L153.579 26.8422L148.339 11.5222Z"
      fill="#0068C7"
    />
    <defs>
      <clipPath id="clip0_137_151">
        <rect
          width="34"
          height="33"
          fill="white"
          transform="translate(0.781128 0.311523)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
